/**
 * Const with params element
 * Hero type1 component
 * Display the hero type 1: h1 + slider text at left + background-img at right
 */
/* Import section */
import React from "react"
import Sliderhero from "../components/sliderhero"
import "../styles/components/herotype1.scss"

/* Declaration function */
const Herotype1 = ( { keyHero, h1title, arrIntro, colorBar} ) => {
  return (
      <div className="wrap-hero-type1">
        <div className="box-content-hero-type1">
          <div className="wrap-maincontent">
            <h1 dangerouslySetInnerHTML={{__html:h1title}} />
          </div>
          <div className="wrap-box-slider">
            <div className="box-slider">
              <div className={`separator-bar ${colorBar}`}></div>
              <Sliderhero keySlider={keyHero} arrText={arrIntro} classSlider="slider-hero-type1" />
            </div>
          </div>
        </div>
        <div className="box-visual-hero-type1"></div>
      </div>
  )
}

/* Export function */
export default Herotype1

