/**
 * Getstarted page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Herotype1 from "../components/herotype1"
import Hubspot from "../components/hubspotform"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/pages/get-started.scss"


/* Page function declaration */
const Getstartedpage = ({data}) => {

  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageGetstarted

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroGetstarted.map(texth1 => (
    arrTextHero.push({textHero: texth1.introHeroGetstarted})
  ))

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero section */}
      <section className="section-hero-getstarted top-section">
        <Herotype1 h1title={dataAcf.titleHeroGetstarted} arrIntro={arrTextHero} colorBar="blue" />
      </section>

      {/*getstarted request form section */}
      <section className="section-request-getstarted">
        <div className="wrap-maincontent wrap-form">
          <h2><span>{dataAcf.titleRequestGetstarted}</span></h2>
          <div className="text-form" dangerouslySetInnerHTML={{ __html:dataAcf.textRequestGetstarted}} />
          <Hubspot formIdentifier={dataAcf.formIdHubspotGetstarted} />
        </div>
      </section>

    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "get-started"}) {
      acfPageGetstarted {
        formIdHubspotGetstarted
        repeaterHeroGetstarted {
          introHeroGetstarted
        }
        textRequestGetstarted
        titleHeroGetstarted
        titleRequestGetstarted
      }
      title
      seo {
        title
        metaDesc
      }
    }
  }
`
/* Export page startedrmations */
export default Getstartedpage
